import React from 'react';
import FeedbackForm from '../components/FeedbackForm';
import { Container, Typography } from '@mui/material';

const FeedbackPage = () => {
  return (
    <Container>
      <Typography variant="h4" style={{ margin: '20px 0' }}>
        Your Feedback
      </Typography>
      <FeedbackForm />
    </Container>
  );
};

export default FeedbackPage;
