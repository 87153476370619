import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, Divider, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import logoImage from '../assets/logo.png';

const Header = () => {
  const { token, logout } = useAuth();
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleMobileMenu = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleSettingsMenu = (event) => {
    if (!isMobile) {
      setSettingsAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setMobileMenuAnchorEl(null);
    setSettingsAnchorEl(null);
  };

  const navigateTo = (path) => {
    navigate(path);
    handleClose();
  };

  const handleLogout = () => {
    logout();
    navigate('/');
    handleClose();
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMenuAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(mobileMenuAnchorEl)}
      onClose={handleClose}
    >
      {token ? (
        <>
          <MenuItem onClick={() => navigateTo('/discover')}>Discover</MenuItem>
          <MenuItem onClick={() => navigateTo('/dashboard')}>Dashboard</MenuItem>
          <MenuItem onClick={() => navigateTo('/create')}>Add</MenuItem>
          <Divider />
          <MenuItem onClick={() => navigateTo('/settings')}>Settings</MenuItem>
          <MenuItem onClick={() => navigateTo('/gap')}>Race Times</MenuItem>
          <MenuItem onClick={() => navigateTo('/settings/feedback')}>Feedback</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </>
      ) : (
        <>
          <MenuItem onClick={() => navigateTo('/login')}>Login</MenuItem>
          <MenuItem onClick={() => navigateTo('/register')}>Register</MenuItem>
        </>
      )}
    </Menu>
  );

  const settingsMenuId = 'primary-search-account-menu-settings';
  const renderSettingsMenu = (
    <Menu
      anchorEl={settingsAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      id={settingsMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={Boolean(settingsAnchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={() => navigateTo('/settings')}>Settings</MenuItem>
      <MenuItem onClick={() => navigateTo('/gap')}>Race Times</MenuItem>
      <MenuItem onClick={() => navigateTo('/settings/feedback')}>Feedback</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar className="header" position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => navigate('/')}>
            <img src={logoImage} alt="Run Adventure Logo" style={{ height: '40px' }} />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 } }>
            Runventura
          </Typography>
          {isMobile ? (
            <>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenu}
              >
                <MenuIcon />
              </IconButton>
              {renderMobileMenu}
            </>
          ) : (
            <>
              {token ? (
                <>
                  <Button color="inherit" onClick={() => navigateTo('/discover')}>Discover</Button>
                  <Button color="inherit" onClick={() => navigateTo('/dashboard')}>Dashboard</Button>
                  <IconButton color="inherit" onClick={() => navigateTo('/create')}>
                    <AddCircleIcon />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    aria-controls={settingsMenuId}
                    aria-haspopup="true"
                    onClick={handleSettingsMenu}
                  >
                    <AccountBoxIcon/>
                  </IconButton>
                  {renderSettingsMenu}
                </>
              ) : (
                <>
                  <Button color="inherit" onClick={() => navigateTo('/login')}>Login</Button>
                  <Button color="inherit" onClick={() => navigateTo('/register')}>Register</Button>
                </>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
