import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import { apiUrl, mapProviderUrl } from '../config';
import { useAuth } from '../components/AuthContext';

const AdventuresMap = React.memo(() => {
  const [geojsonData, setGeojsonData] = useState(null);
  const { token } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/adventures/map`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setGeojsonData(response.data);
      } catch (error) {
        console.error('Error fetching GeoJSON data:', error);
      }
    };

    fetchData();
  }, [token]);

  const style = (feature) => {
    return {
      color: '#2196f3',
      weight: 3,
      opacity: 1,
    };
  };

  return (
    <MapContainer
        center={[46.8182, 8.2275]}  // Central coordinates for Switzerland
        zoom={8}
        scrollWheelZoom={true}
        style={{ width: "100%", height: "calc(80vh - 4rem)" }}
      >
        <TileLayer
          url={mapProviderUrl}
        />
        
        {geojsonData && 
          <GeoJSON 
            data={geojsonData} 
            style={style}
            onEachFeature={(feature, layer) => {
              layer.bindPopup(`
                <strong><br><a href="/adventure/${feature.properties.id}">${feature.properties.title}</a></strong>
                <br/>${feature.properties.distance.toFixed(2)} km
                <br/>${new Date(feature.properties.race_date).toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
              `);
            }}
          />
        }
  </MapContainer>
  );
});

export default AdventuresMap;