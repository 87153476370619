import React from 'react';
import { Container, Typography, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Footer = () => {
    const theme = useTheme();

    return (
        <>
            <Container className="footer" maxWidth={false} style={{ padding: '20px', textAlign: 'center', backgroundColor: theme.palette.background.default }}>
                <Typography variant="body1" color="textPrimary">
                    © {new Date().getFullYear()} Runventura
                </Typography>
                <Link href="/privacy-policy" color="inherit" style={{ margin: '0 10px' }}>Privacy Policy</Link>
                <Link href="/imprint" color="inherit" style={{ margin: '0 10px' }}>Imprint</Link>
                <Link href="/terms-and-conditions" color="inherit" style={{ margin: '0 10px' }}>Terms & Conditions</Link>
            </Container>
        </>
    );
};

export default Footer;
