import React from 'react';
import { Container, Typography, Link } from '@mui/material';

const PrivacyPolicyPage = () => {
  return (
    <Container>
      <Typography variant="h4" style={{ margin: '20px 0' }}>
        Privacy Policy
      </Typography>

      <Typography variant="body1" paragraph>
        At Runventura, we prioritize your privacy and are transparent about how your data is used. We collect data solely for operating our platform and enhancing your experience.
      </Typography>

      <Typography variant="body1" paragraph>
        Your data is secure with us. We do not sell, trade, or engage in any underhanded practices with your personal information.
      </Typography>

      <Typography variant="body1" paragraph>
        Data storage and processing are entrusted to MongoDB, Inc. and Google Cloud Services. You can review Google Cloud's privacy practices at <Link href="https://cloud.google.com/terms/data-processing-terms" target="_blank">Google Cloud Data Processing and Security Terms</Link>.
      </Typography>

      <Typography variant="body1" paragraph>
        Should you wish to inquire about the personal data we hold or request its deletion, please contact us at Email: <a href="mailto:privacy@Runventura.com">privacy@runventura.com</a>.
      </Typography>

      <Typography variant="body1" paragraph>
        For users logging into our platform, we store essential data in local storage on your device (similar to cookies) to ensure a seamless and personalized experience.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicyPage;
