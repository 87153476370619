import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Card,
  Typography,
  CardContent,
  TextField,
  Button,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { apiUrl } from '../config';
import axios from 'axios';
import { useAuth } from '../components/AuthContext';
import { useNavigate} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

const AdventureAidStationsPage = () => {
  const { id } = useParams();
  const { token } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    const fetchAidStations = async () => {
      try {
        const response = await axios.get(`${apiUrl}/adventure/${id}/aid-stations`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) 
        {
          setEntries(Array.isArray(response.data) ? response.data : []);
        }
      } catch (error) {
        console.error('Error fetching aid stations:', error);
      }
    };

    fetchAidStations();
  }, [token, id]);

  const handleInputChange = (index, field) => (event) => {
    const newEntries = [...entries];
    newEntries[index][field] = event.target.value;
    setEntries(newEntries);
  };

  const addAidStation = () => {
    const newEntries = [...entries, { name: '', distance: '', remarks: '', drinks: '', food: '' }];
    setEntries(newEntries);
  };

  const storeData = async () => {
    try {
      const validEntries = entries.filter(entry => entry.distance !== '');

      if (validEntries.length < 1) {
        setErrorMessage("Please provide at least one aid station entry with distance");
        return;
      }

      setErrorMessage('');

      const jsonData = JSON.stringify(validEntries);

      const response = await axios.post(
        `${apiUrl}/adventure/${id}/aid-stations`,
        jsonData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        }
      );

      if (response.status === 201) {
        console.log('success');
        const intendedPath = localStorage.getItem('adventurePath') || '/adventure/id';
        localStorage.removeItem('intendedPath');
        navigate(intendedPath, { replace: true });
      }
      else {
        console.log(response.status);
      }
    } catch (error) {
      console.error('Error storing data:', error);
    }
  };

  const deleteAidStation = (index) => () => {
    const newEntries = [...entries];
    newEntries.splice(index, 1);
    setEntries(newEntries);
  };

  return (
    <>
      {errorMessage && (
         <div className="error-message">
          <ErrorOutlineIcon className="error-icon" />
          {errorMessage}
        </div>
      )}
      <Container className="card-container">
        <Grid container spacing={5}>
          <Grid item xs={20}>
            <Card>
            <CardContent> 
                <Typography variant="h5" gutterBottom>
                  Aid station
                </Typography>
                {entries.map((entry, index) => (
                  <div key={index} style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
                    <CardContent style={{ width: '40%' }}> 
                    <TextField
                      label="Name"
                      variant="outlined"
                      fullWidth
                      value={entry.name}
                      onChange={handleInputChange(index, 'name')}
                      style={{ marginBottom: '16px' }}
                    />
                    <TextField
                      label="Distance from start in km"
                      variant="outlined"
                      fullWidth
                      value={entry.distance}
                      onChange={handleInputChange(index, 'distance')}
                      style={{ marginBottom: '16px' }}
                    />
                    <TextField
                      label="Remarks"
                      variant="outlined"
                      fullWidth
                      value={entry.remarks}
                      onChange={handleInputChange(index, 'remarks')}
                      style={{ marginBottom: '16px' }}
                    />
                    </CardContent>
                    <CardContent style={{ width: '70%' }}> 
                    <TextField
                        label="Drinks"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={7}
                        value={entry.drinks}
                        onChange={handleInputChange(index, 'drinks')}
                        style={{ marginBottom: '16px' }}
                    />
                    </CardContent>
                    <CardContent style={{ width: '70%' }}> 
                     <TextField
                      label="Food"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={7} 
                      value={entry.food}
                      onChange={handleInputChange(index, 'food')} 
                      style={{ marginBottom: '16px' }}
                  />
                    </CardContent>
                    <CardContent>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={deleteAidStation(index)}
                      style={{ marginLeft: '8px' }}
                    >
                        <DeleteIcon />
                    </Button>
                  </CardContent>
                  </div>
                ))}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={addAidStation}
                >
                  Add aid station
                </Button>
              </CardContent>
          </Card>
          <Card>
            <CardContent>                
              <Button
                  variant="contained"
                  color="primary"
                  onClick={storeData}
                >
                  Store Data
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AdventureAidStationsPage;
