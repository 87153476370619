import React from 'react';
import { Container, Typography } from '@mui/material';

const TermsConditionsPage = () => {
  return (
    <Container>
      <Typography variant="h4" style={{ margin: '20px 0' }}>
        Terms & Conditions
      </Typography>

      <Typography variant="body1" paragraph>
        Welcome to Runventura! By using our platform, you agree to our terms and conditions as outlined below.
      </Typography>

      <Typography variant="body1" paragraph>
        When you provide feedback, we may reach out to you for follow-up questions or clarifications to improve our services.
      </Typography>

      <Typography variant="body1" paragraph>
        Please be aware that adventures shared publicly on our platform are visible to all users. Consider this before sharing any personal or sensitive information.
      </Typography>

      <Typography variant="body1" paragraph>
        By using our services, you consent to the storage and use of your data in accordance with our <a href='/privacy-policy'> Privacy Policy. </a>
      </Typography>
    </Container>
  );
};

export default TermsConditionsPage;
