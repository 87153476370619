import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../config';
import { useAuth } from '../components/AuthContext';
import { Card, CardActionArea, CardContent, Typography, Grid, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Races = React.memo(() => {
  const [raceData, setRaceData] = useState([]);
  const { token } = useAuth();
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/activities/races`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRaceData(response.data['features']);
        console.log(response.data['features']);
      } catch (error) {
        console.error('Error fetching race data:', error);
      }
    };

    fetchData();
  }, [token]);

  return (
    <>
      <Typography variant="h5" component="div" sx={{ mb: 2 }}>
        Past Races
      </Typography>
      <Box sx={{ padding: 3 }}>
      <Grid container direction="column" spacing={2}>
        {raceData.length > 0 ? (
          raceData.map((race, index) => (
            <Grid item xs={12} key={index}>
              <Card sx={{
                maxWidth: 345, 
                margin: 'auto',
                border: '1px solid',
                borderColor: theme.palette.divider,
                boxShadow: 3
              }}>
                <CardActionArea href={`/adventure/${race['id']}`}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {race['properties']['name']}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {new Date(race['properties']['start_date']).toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <strong>{(race['properties']['distance']/1000).toFixed(2)} km</strong> ({race['properties']['elevation_gain'].toFixed(0) + 'm'})
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="subtitle1" color="text.secondary">
            No adventures available
          </Typography>
        )}
      </Grid>
    </Box>
    </>
  );
});

export default Races;
