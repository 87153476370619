import React, { useState } from 'react';
import { Button, TextField, Typography, Container, Paper } from '@mui/material';
import axios from 'axios';
import { apiUrl } from '../config';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [registrationStatus, setRegistrationStatus] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const isValidEmail = (email) => {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return emailRegex.test(email);
  };

  const isValidPassword = (password) => {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
      return passwordRegex.test(password);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      setRegistrationStatus('Invalid email address.');
      return;
    }

    if (!isValidPassword(password)) {
        setRegistrationStatus('Password must be at least 8 characters long, include uppercase and lowercase letters, and a number');
        return;
    }

    try {
      const response = await axios.post(`${apiUrl}/auth/register`, { email, password });

      if (response.status === 201) {
          const { token } = response.data;
          login(token);
          //navigate('/dashboard', { replace: true });
          const intendedPath = localStorage.getItem('intendedPath') || '/';
          localStorage.removeItem('intendedPath');
          navigate(intendedPath, { replace: true });
          setRegistrationStatus('Registration successful!');
      } else {
          setRegistrationStatus('Registration failed. Please try again.');
      }
  } catch (error) {
      if (error.response && error.response.status === 409) {
        setRegistrationStatus('User already exists, ');
      } else {
          console.error('Registration failed:', error.message);
          setRegistrationStatus('Registration failed. Please try again.');
      }
  }
};

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3}>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form onSubmit={handleRegister}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Register
          </Button>
        </form>
        {registrationStatus === 'User already exists, ' && (
            <Typography color="textSecondary" variant="body2" gutterBottom>
                {registrationStatus} <a href="/login">log in instead</a>
            </Typography>
        )}
        {registrationStatus !== 'User already exists, ' && registrationStatus && (
            <Typography color="textSecondary" variant="body2" gutterBottom>
                {registrationStatus}
            </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default Register;
