import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography
} from '@mui/material';
import Heatmap from '../components/Heatmap.js';
import Races from '../components/Races.js';
import TopRuns from '../components/TopRuns.js';
import Adventures from '../components/Adventures.js';

const HeatmapPage = () => {
  return (
    <>
      <Container className="card-container" maxWidth="xl" >
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div" className="card-title">
                  Maps of all activities
                </Typography>
                <br/>
                <Heatmap />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
          <Card>
              <CardContent>
                <Typography variant="h5" component="div" className="card-title">
                    Upcoming races
                </Typography>
                <Adventures onlySubscribed={true}/>
              </CardContent>
            </Card>
            <br/>
            <Card>
              <CardContent>
                <Races />
              </CardContent>
            </Card>
            <br />
            <Card>
              <CardContent>
                <TopRuns/>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default HeatmapPage;
