import { List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../config';
import { useAuth } from '../components/AuthContext';

const TopRuns = React.memo(() => {
  const [raceData, setRaceData] = useState([]);
  const { token } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/activities/recent`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRaceData(response.data['features']);
      } catch (error) {
        console.error('Error fetching race data:', error);
      }
    };

    fetchData();
  }, [token]);

  return (
    <>
      <Typography variant="h5" component="div" sx={{ mb: 2 }}>
        Latest Activities <a href='/update'>update</a>
      </Typography>
      <List>
        {raceData.length > 0 ? (
          raceData.map((race, index) => (
            <ListItem key={index}>
              {/* Use an anchor element for a clickable link */}
              <ListItemText
                primary={
                  <a href={`https://www.strava.com/activities/${race['properties']['id']}`}>
                    {race['properties']['name']}
                  </a>
                }
              />
            </ListItem>
          ))
        ) : (
          <ListItem>
            <ListItemText primary="No activities" />
          </ListItem>
        )}
      </List>
    </>
  );
});

export default TopRuns;
