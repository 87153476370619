import React, { useEffect, useState} from 'react';
import { MapContainer, TileLayer, Polyline } from 'react-leaflet';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import { apiUrl, mapProviderUrl } from '../config';
import { useAuth } from '../components/AuthContext';

const AdventureMap = React.memo((props) => {
  const { id } = useParams();
  const [positions, setPositions] = useState(null);
  const { token } = useAuth();

  function calculateCenter(positions) {
    if (positions.length === 0) {
        return null;
    }

    const avgLat = positions.reduce((sum, pos) => sum + pos[0], 0) / positions.length;
    const avgLon = positions.reduce((sum, pos) => sum + pos[1], 0) / positions.length;

    return [avgLat, avgLon];
  }

  // also gets rid of elevation data
  function switchCoordinates(arr) {
    return arr.map(coords => {
        return [coords[1], coords[0]];
    });
  }
  
  useEffect(() => {
    const fetchData = async () => {
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      try {
        const response = await axios.get(`${apiUrl}/adventure/${id}/geojson`, { headers });
        const coordinates = response.data.features.map(feature => feature.geometry.coordinates);
        
        setPositions(switchCoordinates(coordinates[0]));
      } catch (error) {
        console.error('Error fetching race data:', error);
      }
    };

    fetchData();
  }, [id, token]);


  return (
    <div>
    { positions &&
    <>
      <MapContainer
          center={calculateCenter(positions)}
          zoom={12}
          scrollWheelZoom={true}
          style={{ width: "100%", height: "calc(70vh - 4rem)" }}
        >
        <TileLayer
          url={mapProviderUrl}
        >
        </TileLayer>
        
        <Polyline
          pathOptions={{ fillColor: 'red', color: 'blue' }}
          positions={positions}
        />
    </MapContainer>
  </>
  }
  </div>
  )
});

export default AdventureMap;