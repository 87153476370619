import React, { useEffect, useState} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { apiUrl } from '../config';
import { useAuth } from '../components/AuthContext';

const RunningTable = () => {
    const [lapTimeData, setLapTimeData] = useState(null);
    const { id } = useParams();
    const { token } = useAuth();

    let pace = 6;
    let lap = 5;

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`${apiUrl}/adventure/${id}/laps?pace=${pace}&lap=${lap}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setLapTimeData(response.data);
          } catch (error) {
            console.error('Error fetching GeoJSON data:', error);
          }
        };
    
        fetchData();
      }, [id, lap, pace, token]);

    const formatPace = (pace) => {
      const minutes = Math.floor(pace);
      const seconds = Math.round((pace - minutes) * 60);
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
      return `${minutes}:${formattedSeconds}/km`;
    };

  return (
    <>
    {lapTimeData &&
    <TableContainer> 
        <p>
          Based on a grad adjusted pace of <b>{formatPace(lapTimeData.pace)}</b> <a href='/gap'>change</a>
        </p>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><b>Distance</b></TableCell>
            <TableCell><b>Elevation Gain</b></TableCell>
            <TableCell><b>Elevation Loss</b></TableCell>
            <TableCell><b>Time Since Start</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {lapTimeData && lapTimeData.laps.map((row) => (
            <TableRow 
              key={row.distance} 
              style={{ 
                  backgroundColor: row.type === 'cutoff_time' ? 'darkgrey' :
                                  row.type === 'aid_station' ? 'lightgrey' : '' 
              }}
            >
              <TableCell component="th" scope="row">{row.distance}</TableCell>
              <TableCell>{row.eleup}</TableCell>
              <TableCell>{row.eledown}</TableCell>
              <TableCell>{row.hour}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    }
    {!lapTimeData &&
      <a href='/gap'> Provide some past race times to get an estimation</a>
    }
    </>
  );
};

export default RunningTable;
