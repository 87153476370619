import React, { useState, useEffect } from 'react';
import { Container, Grid, Card, Typography, CardContent, Button, Snackbar, IconButton, Tooltip, Link} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import AdventureMap from '../components/AdventureMap';
import ElevationProfile from '../components/ElevationProfile';
import LapTimes from '../components/LapTimes';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { apiUrl } from '../config';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';

const AdventurePage = () => {
    const { id } = useParams();
    const [adventure, setAdventure] = useState(null);
    const { token } = useAuth();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigate();

    localStorage.setItem('intendedPath', window.location.pathname);
    localStorage.setItem('adventurePath', window.location.pathname);

    useEffect(() => {
        fetchData();
    }, [id, token]);

    const fetchData = async () => {
        const headers = token ? { Authorization: `Bearer ${token}` } : {};
        try {
            const response = await axios.get(`${apiUrl}/adventure/${id}`, { headers });
            setAdventure(response.data);
        } catch (error) {
            console.error('Error fetching adventure:', error);
        }
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`${apiUrl}/adventure/${id}/delete`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            navigate('/dashboard');
        } catch (error) {
            console.error('Error deleting adventure:', error);
        }
    };

    const handleSubscribe = async () => {
        try {
            const endpoint = adventure.subscriber === 1 ? '/unsubscribe' : '/subscribe';
            await axios.post(`${apiUrl}/adventure/${id}${endpoint}`, {}, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setAdventure({ ...adventure, subscriber: adventure.subscriber === 0 ? 1 : 0 });
            setSnackbarMessage(adventure.subscriber === 0 ? 'Successfully added' : 'Successfully removed');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error updating subscription:', error);
        }
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('gpxFile', selectedFile);

            try {
                await axios.post(`${apiUrl}/adventure/${id}/gpx`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                });
                fetchData();
                setSnackbarMessage('GPX file uploaded successfully');
                setSnackbarOpen(true);            
            } catch (error) {
                console.error('Error uploading GPX file:', error);
                setSnackbarMessage('Error while uploading GPX file');
                setSnackbarOpen(true);
            }
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handlePrint = () => {
        window.print();
    };

    return (
        <>
            <Container className="card-container">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                {adventure && (
                                    <>
                                        <h1>{adventure.title}</h1>
                                        <p>
                                            <b>{new Date(adventure.race_date).toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</b> at <b>{new Date(adventure.race_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</b>
                                        </p>
                                        <p>
                                            <b>{adventure.distance.toFixed(2)}km</b>
                                            { (adventure.elevation_gain || adventure.elevation_loss) && 
                                                ` (${adventure.elevation_gain ? adventure.elevation_gain.toFixed(0) + 'm up' : ''}${adventure.elevation_gain && adventure.elevation_loss ? ' / ' : ''}${adventure.elevation_loss ? adventure.elevation_loss.toFixed(0) + 'm down' : ''})` }
                                        </p>
                                        <p>
                                            <a href={`http://${adventure.link}`}>{adventure.link}</a>
                                        </p>
                                    </>
                                )}
                                <Grid container justifyContent="flex-start">
                                    {adventure && token && (
                                        <Tooltip title={adventure.subscriber === 1 ? "Remove from my races" : "Add to my races"}>
                                            <IconButton 
                                                color="inherit" 
                                                onClick={handleSubscribe}
                                                aria-label={adventure.subscriber === 1 ? 'Remove from my list' : 'Add to my list'}
                                            >
                                                {adventure.subscriber === 1 ? <PlaylistRemoveIcon /> : <PlaylistAddIcon />}
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                        <Tooltip title="Print">
                                            <IconButton color="inherit" onClick={handlePrint}>
                                                <PrintIcon />
                                            </IconButton>
                                        </Tooltip>

                                </Grid>
                                <Grid container justifyContent="flex-start">
                                    <a href={`/adventure/${id}/aid-station`}>Add aid station</a>
                                </Grid>
                                <Grid container justifyContent="flex-start">
                                    <a href={`/adventure/${id}/cutoff-times`}>Add cut-off times</a>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    {adventure && (
                        <>
                            {!adventure.gpx_file_id && (
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <p>Add Missing GPX file</p>
                                            <Grid item xs={12}>
                                                <input
                                                    type="file"
                                                    accept=".gpx"
                                                    onChange={handleFileChange}
                                                />
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleUpload}
                                                    disabled={!selectedFile}
                                                >
                                                    Add GPX Data
                                                </Button>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                            {adventure.gpx_file_id && (
                                <>
                                    <Grid item xs={12}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="h5" component="div" className="card-title">
                                                    Map
                                                </Typography>
                                                <br/>
                                                <AdventureMap mapData={adventure.id}/>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="h5" component="div" className="card-title">
                                                    Elevation Profile
                                                </Typography>
                                                <br/>
                                                <ElevationProfile mapData={adventure.id}/>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="h5" component="div" className="card-title">
                                                    Lap Times
                                                </Typography>
                                                <br/>
                                                <LapTimes adventureId={adventure.id}/>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                    {adventure && adventure.owner === 1 && (
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleDelete}
                            >
                                Delete Adventure
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Container>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </>
    );
};

export default AdventurePage;
