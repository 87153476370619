import React, { useState } from 'react';
import { TextField, Button, Container, Typography } from '@mui/material';
import axios from 'axios';
import { apiUrl } from '../config';
import { useAuth } from '../components/AuthContext';

const FeedbackForm = () => {
  const [feedback, setFeedback] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const { token } = useAuth();

  const validateForm = () => {
    if (!feedback) {
      setMessage('Please provide feedback.');
      setError(true);
      return false;
    }
    setError(false);
    setMessage('');
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    try {
      const formData = new FormData();
      formData.append('feedback', feedback);

      const response = await axios.post(`${apiUrl}/settings/feedback`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 201) {
        setFeedback('');
        setMessage('Feedback sent, thanks for your feedback');
        setError(false);
      }
    } catch (error) {
      console.error('Error sending feedback:', error);
      setMessage('Sorry, could not send the feedback, please write an email to matthias.suter@gmail.com');
      setError(true);
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Your Feedback"
          multiline
          rows={4}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '10px' }}>
          Submit Feedback
        </Button>
        {message && (
          <Typography 
            style={{ marginTop: '20px', color: error ? 'red' : 'green' }}
            variant="body1"
          >
            {message}
          </Typography>
        )}
      </form>
    </Container>
  );
};

export default FeedbackForm;
