import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Card,
  Typography,
  CardContent,
  TextField,
  Button,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { apiUrl } from '../config';
import axios from 'axios';
import { useAuth } from '../components/AuthContext';
import { useNavigate} from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

const CreateGapPage = () => {
  const [entries, setEntries] = useState([
    {
      "distance": "10",
      "time": "00:49:44"
    },
    {
      "distance": "42.195",
      "time": "03:41:40"
    }
  ]);
  const { token } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const convertSecondsToTimeFormat = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const fetchReferenceTimes = async () => {
      try {
        const response = await axios.get(`${apiUrl}/performance/reference`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          const formattedEntries = response.data.map(entry => ({
            ...entry,
            time: convertSecondsToTimeFormat(entry.time),
          }));
          if (formattedEntries && formattedEntries.length > 0) {
            setEntries(formattedEntries);
          }
        }
      } catch (error) {
        console.error('Error fetching reference times:', error);
      }
    };

    fetchReferenceTimes();
  }, [token]);

  const handleInputChange = (index, field) => (event) => {
    const newEntries = [...entries];
    newEntries[index][field] = event.target.value;
    setEntries(newEntries);
  };

  const validateTimeInput = (input) => {
    // Validate time only if there is an input
    return input === '' || /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/.test(input);
  };

  const addReferenceTime = () => {
    const newEntries = [...entries, { distance: '', time: '' }];
    setEntries(newEntries);
  };

  const convertTimeToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const storeData = async () => {
    try {
      // Filter entries with both distance and time set
      const validEntries = entries.filter(entry => entry.distance !== '' && entry.time !== '');

      if (validEntries.length < 2) {
        setErrorMessage("Please provide at least two distinct race times to get an estimation");
        return;
      }

      setErrorMessage('');

      const entriesInSeconds = validEntries.map(entry => ({
        distance: entry.distance,
        time: convertTimeToSeconds(entry.time),
      }));

      const jsonData = JSON.stringify(entriesInSeconds);

      const response = await axios.post(
        `${apiUrl}/performance/gap`,
        jsonData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        }
      );

      if (response.status === 201) {
        const intendedPath = localStorage.getItem('adventurePath') || '/dashboard';
        localStorage.removeItem('intendedPath');
        navigate(intendedPath, { replace: true });
      }
    } catch (error) {
      console.error('Error storing data:', error);
    }
  };

  const deleteReferenceTime = (index) => () => {
    const newEntries = [...entries];
    newEntries.splice(index, 1);
    setEntries(newEntries);
  };

  return (
    <>
      {errorMessage && (
         <div className="error-message">
          <ErrorOutlineIcon className="error-icon" />
          {errorMessage}
        </div>
      )}
      <Container className="card-container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <p>
                  To enhance the accuracy of our prediction, please provide times for at least two different distances. The greater the variety in these distances, the more precise our prediction will be. You may use your most recent best time from a flat race or alternatively, you can refer to estimates from other reliable sources, such as Garmin's race prediction feature.                
                </p>
                {entries.map((entry, index) => (
                  <div key={index} style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
                    <TextField
                      label="Distance"
                      variant="outlined"
                      fullWidth
                      value={entry.distance}
                      onChange={handleInputChange(index, 'distance')}
                      style={{ marginRight: '16px' }}
                    />
                    <TextField
                      label="Time"
                      variant="outlined"
                      fullWidth
                      value={entry.time}
                      onChange={handleInputChange(index, 'time')}
                      error={entry.time !== '' && !validateTimeInput(entry.time)}
                      helperText={entry.time !== '' && !validateTimeInput(entry.time) ? "Invalid time format (hh:mm:ss)" : ""}
                    />
                     <Button
                      variant="outlined"
                      color="secondary"
                      onClick={deleteReferenceTime(index)}
                      style={{ marginLeft: '8px' }}
                    >
                        <DeleteIcon />
                    </Button>
                  </div>
                ))}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={addReferenceTime}
                >
                  Add Reference Time
                </Button>
              </CardContent>
          </Card>
          <Card>
            <CardContent>                
              <Button
                  variant="contained"
                  color="primary"
                  onClick={storeData}
                >
                  Store Data
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CreateGapPage;
