import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from '../config';
import { useAuth } from '../components/AuthContext';

const UpdatePage = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios.get(`${apiUrl}/strava/activities`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('Activities updated successfully');
        navigate('/dashboard', { replace: true });
      } catch (error) {
        console.error('Error updating activities:', error);
      } finally {
      }
    };

    fetchData();
  }, [navigate, token]);

  return (
    <div>
      <h1>Updating Activities...</h1>
      {/* You can add a loading spinner or any other UI element here */}
    </div>
  );
};

export default UpdatePage;
