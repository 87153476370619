import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CreateAdventurePage from './pages/CreateAdventurePage.js';
import AdventurePage from './pages/AdventurePage.js';
import ExpeditionPage from './pages/ExpeditionPage.js';
import GapPage from './pages/GapPage.js';
import Header from './components/Header.js';
import Login from './pages/Login.js';
import { AuthProvider, useAuth } from './components/AuthContext.js';
import UpdatePage from './pages/UpdatePage.js';
import Register from './pages/Register.js';
import DiscoverPage from './pages/DiscoverPage.js';
import HeatmapPage from './pages/HeatmapPage.js';
import DashboardPage from './pages/DashboardPage.js';
import SettingsPage from './pages/SettingsPage.js';
import FeedbackPage from './pages/FeedbackPage.js';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage.js';
import Footer from './components/Footer.js';
import TermsConditionPage from './pages/TermsConditionsPage.js';
import ImprintPage from './pages/ImprintPage.js';
import AdventureAidStationsPage from './pages/AdventureAidStationsPage.js';
import AdventureCutoffTimesPage from './pages/AdventureCutoffTimesPage.js';

const ProtectedRoute = ({ children }) => {
  const { token } = useAuth();
  
  if (!token) {
    localStorage.setItem('intendedPath', window.location.pathname);
    return <Navigate to="/login" replace />;
  }

  return children;
};

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Header/>
        <Routes>
          <Route path="/login" exact element={<Login/>} />
          <Route path="/register" exact element={<Register/>} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicyPage/>} />
          <Route path="/terms-and-conditions" exact element={<TermsConditionPage/>} />
          <Route path="/imprint" exact element={<ImprintPage/>} />
          <Route path="/adventure/:id" element={
              <AdventurePage/>
          }/>
          <Route path="/Expedition/:id" element={
              <ExpeditionPage/>
          }/>
           <Route path="/adventure/:id/aid-station" element={
              <AdventureAidStationsPage/>
          }/>
          <Route path="/adventure/:id/cutoff-times" element={
              <AdventureCutoffTimesPage/>
          }/>
          <Route path="/" element={
            <ProtectedRoute>
              <HeatmapPage/>
            </ProtectedRoute>
          } />
          <Route path="/dashboard" element={
            <ProtectedRoute>
              <HeatmapPage/>
            </ProtectedRoute>
          } />
          <Route path="/discover" element={
            <ProtectedRoute>
              <DiscoverPage/>
            </ProtectedRoute>
          } />
          <Route path="/create" element={
            <ProtectedRoute>
              <CreateAdventurePage/>
            </ProtectedRoute>
          } />
          <Route path="/update" element={
            <ProtectedRoute>
              <UpdatePage/>
            </ProtectedRoute>
          } />
           <Route path="/gap" element={
            <ProtectedRoute>
              <GapPage/>
            </ProtectedRoute>
          } />
          <Route path="/settings" element={
            <ProtectedRoute>
              <SettingsPage/>
            </ProtectedRoute>
          } />
          <Route path="/settings/feedback" element={
            <ProtectedRoute>
              <FeedbackPage/>
            </ProtectedRoute>
          } />
        </Routes>
        <Footer/>
      </AuthProvider>
    </Router>
  );
}

export default App;
