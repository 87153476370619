import React from 'react';
import { Container } from '@mui/material';
import AdventureForm from '../components/AdventureForm';

const CreateAdventurePage = () => {

  return (
    <>
       <Container className="card-container" maxWidth="xl" >
        <AdventureForm/>
      </Container>
    </>
  );
};

export default CreateAdventurePage;
