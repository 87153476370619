import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';


const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#34568B',
    },
    secondary: {
      main: '#B2B8A3',
    },
    accent: {
      main: '#F28C28',
    },
    background: {
      default: '#fafafa',
      paper: '#ffffff',
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <App/>
      </React.StrictMode>
    </ThemeProvider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();