import React, { useState, useEffect } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { apiUrl } from '../config';
import { useAuth } from '../components/AuthContext';

const ElevationProfile = () => {
  const { id } = useParams();
  const [elevationData, setElevationData] = useState(null);
  const { token } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/adventure/${id}/geojson`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const elevationPoints = response.data.features[0].properties['points'].map(feature => {
          const { coordinates } = feature.geometry;
          const { elevation_gain_since_start, elevation_loss_since_start } = feature.properties;
          const elevation = coordinates[2]; // Assuming the third element is elevation
          const cumulativeDistance = feature.properties.distance_since_start;
  
          return {
            distance: cumulativeDistance,
            elevation: elevation,
            elevationGain: elevation_gain_since_start,
            elevationLoss: elevation_loss_since_start
          };
        });

        setElevationData(elevationPoints);
  
      } catch (error) {
        console.error('Error fetching race data:', error);
      }
    };
  
    fetchData();
  }, [id, token]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const formattedDistance = Number(payload[0].payload.distance).toFixed(2);
      const formattedElevation = Number(payload[0].payload.elevation).toFixed(0);
  
      return (
        <div className="custom-tooltip">
          <p>{`Distance: ${formattedDistance}km`}</p>
          <p>{`Elevation: ${formattedElevation}m`}</p>
        </div>
      );
    }
  
    return null;
  };

  const CustomXAxisTick = (props) => {
    const { x, y, payload } = props;
    const formattedDistance = Number(payload.value).toFixed(0);
  
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fill="#666"
          transform="rotate(0)"
        >
          {formattedDistance} km
        </text>
      </g>
    );
  };

  let minYAxisValue = elevationData && Math.min(...elevationData.map(item => item.elevation));
  minYAxisValue = minYAxisValue - (minYAxisValue * 0.05); // Adjust to start a bit above the lowest point


  return (
    <div>
    { elevationData &&
      <>
      <ResponsiveContainer height='100%' aspect={4.0/1.0}>
        <LineChart data={elevationData}>
          <XAxis
            dataKey="distance"
            tick={<CustomXAxisTick />}
          />
          <YAxis dataKey="elevation" domain={[minYAxisValue, 'auto']} />
          <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
          <Line type="monotone" dataKey="elevation" stroke="#8884d8" dot={{ stroke: '#8884d8', strokeWidth: 2, fill: '#8884d8', r: 1 }}/>
          <Tooltip 
            content={<CustomTooltip />} 
            wrapperStyle={{ border: '1px solid #ccc', borderRadius: '5px', padding: '5px' }}
          />
        </LineChart>
      </ResponsiveContainer>
      </>
    }
    </div>
  );
};

export default ElevationProfile;