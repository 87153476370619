// AdventureForm.js
import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Card, CardContent, Checkbox, FormControlLabel } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DatePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import { apiUrl } from '../config';
import { useAuth } from '../components/AuthContext';


const AdventureForm = () => {
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [gpxFile, setGpxFile] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const { token } = useAuth();
  const [errors, setErrors] = useState({});
  const [isPublic, setIsPublic] = useState(false);
  const [distance, setDistance] = useState('');

  const navigate = useNavigate();

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleLinkChange = (event) => {
    setLink(event.target.value);
  };

  const handleFileChange = (event) => {
    setGpxFile(event.target.files[0]);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handlePublicChange = (event) => {
    setIsPublic(event.target.checked);
  };
  

  const validateForm = () => {
    let formIsValid = true;
    let newErrors = {};

    if (!title) {
      formIsValid = false;
      newErrors.title = 'Title is required.';
    }

    if (!gpxFile && !distance) {
      formIsValid = false;
      newErrors.gpxFile = 'Either GPX file or Distance is required.';
    }

    if (!selectedDate) {
      formIsValid = false;
      newErrors.selectedDate = 'Race date and time is required.';
    }

    if (distance && isNaN(distance)) {
        formIsValid = false;
        newErrors.distance = 'Distance must be a number.';
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = async () => {
    try {
      if (!validateForm()) {
        console.error('Please fill in all required fields.');
        return;
      }

      const formData = new FormData();
      formData.append('title', title);
      formData.append('link', link);
      formData.append('gpxFile', gpxFile);
      formData.append('raceDate', selectedDate.toISOString());
      formData.append('public', isPublic);
      formData.append('distance', distance);

      axios.post(
        `${apiUrl}/adventure/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        if (response.status === 201) {
          const path = '/adventure/' + response.data.id;
          navigate(path);
        }
      });
    } catch (error) {
      console.error('Error creating adventure:', error);
    }
  };

  const renderErrorMessages = () => {
    const errorMessages = [];
    for (const key in errors) {
      if (errors[key]) {
        errorMessages.push(<li key={key}>{errors[key]}</li>);
      }
    }
    return errorMessages.length > 0 ? (
      <div style={{ color: 'red', marginBottom: '16px' }}>
        <ul>{errorMessages}</ul>
      </div>
    ) : null;
  };

  return (
    <Container> 
      {renderErrorMessages()}
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
        <Card className="map-card" elevation={0}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Create Adventure
          </Typography>
          </CardContent>
          <CardContent>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={handleTitleChange}
              style={{ marginBottom: '16px' }}
            />
          </CardContent>
          <CardContent>
            <TextField
              label="Distance (km)"
              variant="outlined"
              fullWidth
              value={distance}
              onChange={(e) => setDistance(e.target.value)}
              style={{ marginBottom: '16px' }}
            />
          </CardContent>
          <CardContent>
            GPX track
            <br/>
            <input type="file" accept=".gpx" onChange={handleFileChange} />
          </CardContent>
          <CardContent>
            <DatePicker
              label="Date of the race"
              value={selectedDate}
              onChange={handleDateChange}      
              renderInput={(props) => <TextField {...props} variant="outlined" style={{ marginBottom: '16px' }} />}
              />
           </CardContent>
            <CardContent>   
              <TimePicker
                label="Start time (select 00:00 if not known)"
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(props) => <TextField {...props} variant="outlined" style={{ marginBottom: '16px' }} />}
              />
          </CardContent>
          <CardContent>
            <TextField
              label="Link to the race page"
              variant="outlined"
              fullWidth
              value={link}
              onChange={handleLinkChange}
              style={{ marginBottom: '16px' }}
            />
          </CardContent>
          <CardContent>
            <FormControlLabel
              control={<Checkbox checked={isPublic} onChange={handlePublicChange} />}
              label="Everyone can see the adventure (this is a great way to contribute to the platform)"
            />
          </CardContent>
          <CardContent>
            <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginTop: '16px' }}>
              Create Adventure
            </Button>
          </CardContent>
        </Card>
      </LocalizationProvider>
    </Container>
  );
};

export default AdventureForm;
