import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON, LayersControl, WMSTileLayer } from 'react-leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { apiUrl } from '../config';
import { useAuth } from '../components/AuthContext';

const Heatmap = React.memo(() => {
  const [geojsonData, setGeojsonData] = useState(null);
  const { token } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/activities/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setGeojsonData(response.data);
      } catch (error) {
        console.error('Error fetching GeoJSON data:', error);
      }
    };

    fetchData();
  }, [token]);

  const getColor = (type) => {
    switch (type) {
      case 'Hike':
        return '#2196f3'; // Blue
      case 'Ride':
        return '#4caf50'; // Green
      case 'Run':
        return '#2196f3'; // Blue
      default:
        return '#ffa36c'; // Light salmon
    }
  };

  const style = (feature) => {
    const type = feature.properties.type;
    return {
      color: getColor(type),
      weight: 3,
      opacity: 1,
    };
  };

  return (
    <MapContainer
      center={[47.3604, 8.3062]}
      zoom={12}
      scrollWheelZoom={true}
      style={{ width: "100%", height: "calc(100vh - 4rem)" }}
    >
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="OpenStreetMap">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Swisstopo Map">
          <TileLayer
            url="https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg"
            attribution='&copy; <a href="https://www.swisstopo.admin.ch/en/home.html">swisstopo</a>'
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Satellite View">
          <TileLayer
            url="https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg"
            attribution='&copy; <a href="https://www.swisstopo.admin.ch/en/home.html">swisstopo</a>'
          />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay checked name="Heatmap">
          {geojsonData &&
            <GeoJSON
              data={geojsonData}
              style={style}
              onEachFeature={(feature, layer) => {
                // Add a popup with information about the activity
                layer.bindPopup(`
                  <strong>${feature.properties.type}</strong>
                  <br><a href="https://www.strava.com/activities/${feature.properties.id}">${feature.properties.name}</a>
                `);
              }}
            />
          }
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Swiss Hiking Trails">
          <WMSTileLayer
            url="https://wms.geo.admin.ch/"
            layers="ch.swisstopo.swisstlm3d-wanderwege"
            format="image/png"
            transparent={true}
            version="1.3.0"
            crs={L.CRS.EPSG3857}
            attribution='&copy; <a href="https://www.swisstopo.admin.ch/en/home.html">swisstopo</a>'
          />
        </LayersControl.Overlay>
      </LayersControl>
    </MapContainer>
  );
});

export default Heatmap;
