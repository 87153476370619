import React from 'react';

const SettingsPage = () => {
  const style = {
    textAlign: 'center',
    color: '#34568B',
    marginTop: '100px', 
  };

  return (
    <div style={style}>
      <br/>
      Coming soon... or is it? 🧐🎉
      <br/>
      Stay tuned for an adventure in settings!
    </div>
  );
};

export default SettingsPage;
