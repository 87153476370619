import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../config';
import { useAuth } from '../components/AuthContext';
import { Card, CardActionArea, CardContent, Typography, Grid, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Adventures = React.memo(({ onlyPublic, onlySubscribed, onlyCreated }) => {
  const [raceData, setRaceData] = useState([]);
  const { token } = useAuth();
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `${apiUrl}/adventures/`; // default endpoint
        if (onlyPublic) {
          endpoint = `${apiUrl}/adventures/public`;
        } else if (onlySubscribed) {
          endpoint = `${apiUrl}/adventures/subscribed`;
        } else if (onlyCreated) {
          endpoint = `${apiUrl}/adventures/created`;
        }
  
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRaceData(response.data);
      } catch (error) {
        console.error('Error fetching race data:', error);
      }
    };
  
    fetchData();
  }, [token, onlyPublic, onlySubscribed, onlyCreated]); 

  return (
    <Box sx={{ padding: 3 }}>
      <Grid container direction="column" spacing={2}>
        {raceData.length > 0 ? (
          raceData.map((race, index) => (
            <Grid item xs={12} key={index}>
              <Card sx={{
                maxWidth: 345, 
                margin: 'auto',
                border: '1px solid',
                borderColor: theme.palette.divider,
                boxShadow: 3
              }}>
                <CardActionArea href={`/adventure/${race['id']}`}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {race['title']}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {new Date(race.race_date).toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <strong>{race.distance.toFixed(2)} km</strong>
                      {race.elevation_gain || race.elevation_loss ? ` (${race.elevation_gain ? race.elevation_gain.toFixed(0) + 'm up' : ''}${race.elevation_gain && race.elevation_loss ? ' / ' : ''}${race.elevation_loss ? race.elevation_loss.toFixed(0) + 'm down' : ''})` : ''}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="subtitle1" color="text.secondary">
            No adventures available
          </Typography>
        )}
      </Grid>
    </Box>
  );
});

export default Adventures;
