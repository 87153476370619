import React from 'react';
import { Container, Typography } from '@mui/material';

const ImprintPage = () => {
  return (
    <Container>
      <Typography variant="h4" style={{ margin: '20px 0' }}>
        Imprint
      </Typography>

      <Typography variant="body1" paragraph>
        If you have any questions or require further information, please feel free to contact us at the following:
      </Typography>

      <Typography variant="body1" paragraph>
        Email: <a href="mailto:hello@Runventura.com">hello@runventura.com</a>
      </Typography>
    </Container>
  );
};

export default ImprintPage;
