import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography
} from '@mui/material';
import Adventures from '../components/Adventures.js';
import heroImage from '../assets/hero.png';
import AdventuresMap from '../components/AdventuresMap.js';

const DiscoverPage = () => {
  return (
    <>
    <Container className="card-container" maxWidth="xl" >
      <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
            <Card
              component="div"
              className="card-title"
              style={{
                backgroundImage: `url(${heroImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                height: '500px'
              }}>
            </Card>
          </Grid>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <AdventuresMap onlyPublic={true} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
        <Card>
            <CardContent>
              <Typography variant="h5" component="div" className="card-title">
                  Upcoming Races
                </Typography>
              <Adventures onlyPublic={true} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
    </>
  );
};

export default DiscoverPage;
